@import "src/theme/config/dimensions";
@import "src/theme/config/palette";
@import "src/theme/config/responsive";

.mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background-color: transparent;
}

.mat-mdc-form-field-focus-overlay {
  background-color: transparent;
}

.mat-mdc-form-field {
  width: 100%;
}

dynamic-form.auth-form {
  display: flex;
  flex-direction: column;
  gap: 16px;

  @include phone() {
    width: calc(100% - #{$dim-double});
  }

  .before-form {
    margin-bottom: $dim-double;

    .header {
      padding: $dim-base 0;

      img {
        outline: none;
      }
    }

    .title {
      padding: 0 $dim-double;

      h1 {
        margin: $dim-triple 0 $dim-double;
      }
    }
  }

  form {
    display: flex;
    flex-direction: column;
    padding: 0 $dim-double $dim-double;

    df-string-input {
      width: 500px;
      margin: 16px auto;

      .mat-mdc-form-field {
        margin-bottom: $dim-base;
        width: 100%;
      }
    }


    .form-footer {
      display: flex;
      flex-direction: column;
      align-items: center;

      .red-asterisks {
        color: $col-error;
      }

      .mdc-button {
        margin-top: $dim-semi-double;
      }
    }
  }
}