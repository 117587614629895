$dim-base: 8px;                       // 8px

$dim-small: $dim-base / 4;            // 2px
$dim-half: $dim-base / 2;             // 4px
$dim-semi-half: $dim-base / 1.5;      // 6px

$dim-semi-double: $dim-base * 1.5;    // 12px
$dim-double: $dim-base * 2;           // 16px
$dim-double-plus: $dim-base * 2.3;    // 16px
$dim-triple: $dim-base * 3;           // 24px
$dim-quadruple: $dim-base * 4;        // 32px

$dim-large: $dim-base * 5;            // 40px
$dim-xlarge: $dim-base * 6;           // 48px
$dim-xxlarge: $dim-base * 7;          // 56px
$dim-xxxlarge: $dim-base * 8;         // 64px
$dim-xxxxlarge: $dim-base * 9;        // 72px
$dim-xxxxxlarge: $dim-base * 10;      // 80px
$dim-xxxxxxlarge: $dim-base * 20;     // 160px
$dim-xxxxxxxlarge: $dim-base * 30;    // 240px

$border-radius: 4px;
$border-radius-big: 12px;
$border-round: 100em;

$main-header-height: $dim-xxxlarge;
