@import 'src/theme/config/typography';
@import 'src/theme/config/palette';
@import 'src/theme/config/dimensions';

html,
body {
  height: 100%;
}

body {
  margin: 0;
  color: #262626;
}

* {
  outline: none;
}

.spacer {
  flex: 1 1 auto;
}

h1,
h2,
h3,
h4,
p {
  margin: 0;
}

p,
a,
li,
span {
  line-height: 150%;
  letter-spacing: -0.02em;
  font-family: $text-font-family;
  font-size: $text-font-size;
  font-weight: 400;
}


h1,
h1 span {
  font-family: $h1-font-family;
  font-size: $font-h1;
  font-weight: 700;
}

h2,
h2 span {
  font-family: $h2-font-family;
  font-size: $font-h2;
  font-weight: bold;
}

h3,
h3 span {
  font-family: $h3-font-family;
  font-size: $font-h3;
  font-weight: 700;
  font-style: normal;
  letter-spacing: -0.02em;
}

h4,
h4 span {
  font-family: $h4-font-family;
  font-size: $font-h4;
  font-weight: bold;
}

a {
  line-height: 1.4;
  color: $col-primary;

  &:hover {
    color: $col-primary-dark;
  }

  &.anchor {
    text-decoration: none;
    color: inherit;
    line-height: inherit;
  }
}

.default-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: $dim-semi-double;
  gap: $dim-semi-double;
}

.text-center {
  text-align: center;
}

p {
  &.big {
    font-size: $text-font-size-big;
  }

  &.large {
    font-size: $text-font-size-large;
  }
}

.bg-teal-light {
  background: #EEF6F9;
}

.py-1 {
  padding-top: $dim-base;
  padding-bottom: $dim-base;
}

.py-2 {
  padding-top: $dim-double;
  padding-bottom: $dim-double;
}

.pt-1 {
  padding-top: $dim-base;
}

.pt-2 {
  padding-top: $dim-double;
}

.link {
  color: $col-primary;
  text-decoration: none;
  font-weight: 700;
  font-size: inherit;
  transition: color 0.3s ease-in-out;
  cursor: pointer;

  &:hover {
    color: $col-primary-dark;
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  appearance: textfield;
  -moz-appearance: textfield;
}
