@import 'src/theme/config/dimensions';
@import 'src/theme/config/palette';
@import 'src/theme/config/typography';

.custom-dialog {
  --mdc-dialog-container-shape: #{$border-radius-big};

  .mat-mdc-dialog-title {
    padding: $dim-semi-double $dim-double;
    background: $gradient-primary-vertical;
    border-radius: $border-radius-big $border-radius-big 0 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;

    h3 {
      margin: 0;
      color: $col-white;
      font-weight: 400;
      font-size: $font-h3;
    }

    .dialog-close-button {
      position: absolute;
      top: $dim-base;
      right: $dim-base;
      color: $col-white;
    }
  }

  .mdc-dialog__content {
    padding: 0 $dim-double $dim-double !important;
  }

  &-form {
    .mdc-dialog__content {
      outline: none;
    }

    .mat-mdc-dialog-actions {
      align-items: center;
      justify-content: center;
    }
  }
}