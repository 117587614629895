a {

  &.mat-mdc-button,
  &.mat-mdc-button-base {
    text-decoration: none;
  }
}

a,
button {
  &.mat-mdc-button-base {
    padding: 10px 20px;
    border-radius: 50px;
    --mdc-protected-button-label-text-color: #fff !important;
    --mdc-filled-button-label-text-color: #fff !important;

    &.big {
      padding: 30px 50px;
    }
  }

  &.mat-secondary {
    background-color: #fff;
    color: #000;
    border: 1px solid var(--grey, #CCC);
  }
}

.mat-mdc-card {
  border-radius: 10px !important;

  .mat-mdc-card-header {
    padding: $dim-double $dim-quadruple;
    border-radius: 10px 10px 0px 0px;
    background: var(--teal, #5DA8C0);

    .mat-mdc-card-title {
      color: var(--white, #FFF);
      font-size: 18px;
      font-weight: 700;
    }
  }

  .mat-mdc-card-content {
    padding: $dim-quadruple;

    h2 {
      padding-bottom: $dim-double;
    }
  }

  .mat-mdc-card-actions {
    padding: 0 0 $dim-quadruple $dim-quadruple;
    gap: $dim-double;
  }
}