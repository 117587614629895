@import "src/theme/config/palette";

.success-panel > .mdc-snackbar__surface {
  background: $col-success !important;
  color: $col-white !important;
}

.error-panel > .mdc-snackbar__surface {
  background: $col-error !important;
  color: $col-white !important;
}
