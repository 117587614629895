@import "src/theme/config/dimensions";
@import "src/theme/config/typography";

.mat-mdc-form-field-type-mat-input {
  margin-bottom: $dim-base;
}

.mat-mdc-form-field-hint-wrapper,
.mat-mdc-form-field-error-wrapper {
  padding: 0 !important;
  font-size: $font-small;
}

.mdc-text-field--disabled.mdc-text-field--filled {
  background-color: transparent;
}

.mat-mdc-text-field-wrapper {
  border: 1px solid var(--light-grey, #DEDEDE);
  border-radius: $dim-half !important;

  .mat-form-field-underline {
    display: none;
  }

  .mdc-line-ripple {
    display: none;
  }
}

mat-form-field {
  line-height: 150%;
  letter-spacing: -0.02em;
  font-family: $text-font-family;
  font-size: $text-font-size;
  font-weight: 400;
}

.mat-mdc-form-field-error {
  color: var(--red, #F66) !important;
  /* Paragraph */
  // font-family: Lato;
  // font-size: 18px;
  // font-style: normal;
  // font-weight: 400;
  // line-height: 150%;
  // letter-spacing: -0.36px;
}
