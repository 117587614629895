$font-hero-1: 112px;
$font-hero-2: 56px;
$font-hero-3: 45px;
$font-hero-4: 34px;

$font-h1: 56px;
$font-h2: 32px;
$font-h3: 24px;
$font-h4: 15px;

$font-button: 18px;

$font-small: 11px;
$font-label: 10px;

$font-family-lato: 'Lato', sans-serif;
$font-family-pt-serif: 'PT Serif', sans-serif;

$h1-font-family: 'PT Serif', sans-serif;
$h2-font-family: 'PT Serif', sans-serif;
$h3-font-family: 'PT Serif', sans-serif;
$h4-font-family: 'PT Serif', sans-serif;

$text-font-family: 'Lato', sans-serif;
$text-font-size: 18px;
$text-font-size-big: 22px;
$text-font-size-large: 40px;

$button-font-family: 'PT Serif', sans-serif;
