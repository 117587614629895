@mixin phone {
  @media screen and (max-width: 400px) {
    @content;
  }
}

@mixin tablet {
  @media screen and (min-width: 401px) and (max-width: 950px) {
    @content;
  }
}
