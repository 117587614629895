$col-primary: #0095d5;
$col-primary-dark: darken($col-primary, 15%);
$col-primary-light: lighten($col-primary, 15%);

$col-accent: #002BD5;
$col-accent-dark: darken($col-accent, 15%);
$col-accent-light: lighten($col-accent, 15%);

$col-teal: #5DA8C0;
$col-teal-dark: darken($col-teal, 50%);

$col-black: var(--black, #262626);
$col-white: #ffffff;
$col-grey: #53565a;
$col-grey-dark: darken($col-grey, 15%);
$col-grey-light: lighten($col-grey, 50%);
$col-gray-light: lighten($col-grey, 50%);
$col-grey-lighter: #fdfdfd;
$col-label: #818181;
$col-black: #000000;

$col-info: #81D9FF;
$col-success: #4CAF50;
$col-warning: #FB8C00;
$col-error: #FF5252;

$col-light-border: #EEEEEE;

$box-shadow: 0 3px 6px rgba(0, 0, 0, .4);

$col-pending: #FB8C00;
$col-completed: #4CAF50;
$col-aborted: #FF5252;

$gradient-primary-vertical: linear-gradient(180deg, #64a8bf 0%, #5DA8C0 100%);

// to generate new palette use http://mcg.mbitson.com/#!?mcgpalette0=%23d7c4b1
// then export using the option called `Angular JS 2 (Material 2)`

$md-primary: (
  50 : #e0f2fa,
  100 : #b3dff2,
  200 : #80caea,
  300 : #4db5e2,
  400 : #26a5db,
  500 : #5DA8C0,
  600 : #008dd0,
  700 : #0082ca,
  800 : #0078c4,
  900 : #0067ba,
  A100 : #e3f1ff,
  A200 : #b0d7ff,
  A400 : #7dbdff,
  A700 : #63b0ff,
  contrast: (50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #000000,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #000000,
  )
);

$md-accent: (
  50 : #fdf1e0,
  100 : #fadcb3,
  200 : #f6c580,
  300 : #f2ae4d,
  400 : #f09c26,
  500 : #ed8b00,
  600 : #eb8300,
  700 : #e87800,
  800 : #e56e00,
  900 : #e05b00,
  A100 : #ffffff,
  A200 : #ffe3d4,
  A400 : #ffc1a1,
  A700 : #ffb188,
  contrast: (50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #000000,
    500 : #000000,
    600 : #000000,
    700 : #000000,
    800 : #000000,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #000000,
  )
);